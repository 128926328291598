import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
// const footerDesc = "";

const Educacion = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="¡Regístrate gratis a nuestros talleres enfocados a Pequeños Negocios💡🚀!" subtitle="⏳ Pocos lugares disponibles." amplitude={appState.amplitude} amplitudeClickEvent="WorkshopSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const workshopsHandler = (url) => {
    window.location.href = url;
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="educacion" title="Educación acerca de Negocios, Emprendimiento e Innovación" description="De emprendedor a emprendedor, compartamos conocimientos y metodologías para fortalecer a millones de PyMEs."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
      {!cookies.consent &&
      <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
      }
      <MainSection title="Educación acerca de Negocios, Emprendimiento e Innovación" position="left" image="hero-education.jpg" actionLabel="Regístrate a nuestros Webinars" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignupWorkshop">
        <AppBar pathname={appState.path}/>
      </MainSection>
      <PageSection maxColumns="3" title="Aprendamos juntos">
        <Card
          date="2019-09-27"
          title="Cuenta bancaria empresarial para tu negocio"
          description="<p><strong>¿Tienes un negocio? Ya sea una PyME, Startup o eres Freelancer, en esta guía te explicamos la forma más fácil de abrir tu cuenta bancaria, totalmente en línea, sin pinsar un sólo banco.</strong></p>"
          action={() => workshopsHandler("https://evvafinanzas.com/stories/GuiaCuentaNegocio/")}
          actionLabel="Acceder"
          amplitudeClickEvent="ClickHowToGetAccountGuide"
          amplitude={appState.amplitude}
          padding
          shadow
        />
        <Card
          date="2019-06-26"
          title="Taller de Mercadotecnia Digital"
          description="<p><strong>Nuestro primer webinar enfocado en Mercadotecnia Digital para Pequeños Negocios, impartido por nuestro Experto Alex Ardavín. Video disponible en nuestro grupo de Facebook.</strong></p>"
          action={() => workshopsHandler("https://www.facebook.com/groups/2348327585490643/?source_id=498362467269198")}
          actionLabel="Acceder"
          amplitudeClickEvent="ClickMKTWorkshop"
          amplitude={appState.amplitude}
          padding
          shadow
        />
        <Card
          date="Muy pronto"
          title="Reto Millenial Money"
          description="<p><strong>Descubre las reglas del juego del dinero y crea un plan para lograr tu independencia financiera. Un Reto de 21 días de finanzas personales iniciando este 1ro. de Marzo. </strong></p>"
          action={() => workshopsHandler("https://evvafinanzas.com/revolucion-financiera/")}
          actionLabel="Acceder"
          amplitudeClickEvent="ClickRevFinWorkshop"
          amplitude={appState.amplitude}
          padding
          shadow
        />
      </PageSection>
      <SecondarySection
        title="Juntos crecemos"
        shortDescription="De emprendedores para emprendedores. Somos gente como tú que entiende tus necesidades. Nuestro objetivo final es que te enfoques en lo que realmente importa: tu empresa."
        position="center"
        image="evva-together.jpg"
        actionLabel="Regístrate, es gratis"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignupWorkshop"
      />
    </Layout>
  )
};

export default Educacion
